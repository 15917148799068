import '@backstage/cli/asset-types';
import { AppRuntime } from '@tpb/runtime';
import { plugin as plugin0 } from '@tpb/plugin-kubernetes-orm';
import { plugin as plugin1 } from '@tpb/plugin-search';
import { plugin as plugin2 } from '@tpb/plugin-catalog';
import { plugin as plugin3 } from '@tpb/plugin-techdocs';
import { plugin as plugin4 } from '@tpb/plugin-api-docs';
import { plugin as plugin5 } from '@vmware-tanzu/tdp-plugin-login';
import { plugin as plugin6 } from '@tpb/plugin-scm';
import { plugin as plugin7 } from '@tpb/plugin-api-scoring';
import { plugin as plugin8 } from '@tpb/plugin-app-accelerator-scaffolder';
import { plugin as plugin9 } from '@tpb/plugin-app-accelerator-promotion';
import { plugin as plugin10 } from '@tpb/plugin-app-live-view';
import { plugin as plugin11 } from '@tpb/plugin-auth0-auth';
import { plugin as plugin12 } from '@tpb/plugin-azure-auth';
import { plugin as plugin13 } from '@tpb/plugin-bitbucket-auth';
import { plugin as plugin14 } from '@tpb/plugin-github-auth';
import { plugin as plugin15 } from '@tpb/plugin-gitlab-auth';
import { plugin as plugin16 } from '@tpb/plugin-google-auth';
import { plugin as plugin17 } from '@tpb/plugin-guest-auth';
import { plugin as plugin18 } from '@tpb/plugin-oidc-auth';
import { plugin as plugin19 } from '@tpb/plugin-okta-auth';
import { plugin as plugin20 } from '@tpb/plugin-onelogin-auth';
import { plugin as plugin21 } from '@tpb/plugin-pendo-analytics';
import { plugin as plugin22 } from '@tpb/plugin-rrv';
import { plugin as plugin23 } from '@tpb/plugin-security-analysis-frontend';
import { plugin as plugin24 } from '@tpb/plugin-supply-chain';
import { plugin as plugin25 } from '@tpb/plugin-vmware-cloud-services-auth';
import { plugin as plugin26 } from '@tpb/dora';
import { plugin as themePlugin } from '@tpb/plugin-clarity-theming';
import '@tpb/plugin-clarity-theming/style/clarity.css';

new AppRuntime([
    themePlugin(),
    plugin0(),
    plugin1(),
    plugin2(),
    plugin3(),
    plugin4(),
    plugin5(),
    plugin6(),
    plugin7(),
    plugin8(),
    plugin9(),
    plugin10(),
    plugin11(),
    plugin12(),
    plugin13(),
    plugin14(),
    plugin15(),
    plugin16(),
    plugin17(),
    plugin18(),
    plugin19(),
    plugin20(),
    plugin21(),
    plugin22(),
    plugin23(),
    plugin24(),
    plugin25(),
    plugin26(),
]).render();
